import {
  collection,
  query,
  where,
  onSnapshot,
  addDoc,
  doc,
  getDocs,
  deleteDoc,
  arrayUnion,
  getFirestore,
  updateDoc,
  setDoc,
  getDoc,
  limit,
} from "firebase/firestore";
import { db } from "../../firebase/firebase";
import Constants from "../../constants/Constants";
import { setUsers } from "../UserSlice";
import axios from "axios";

export const getUsersByKeyword = async (keyword, endsWithKeyword) => {
  try {
    const response = await axios.post(
      "https://mkygk76g4d3k6fqd5qvr7xyxea0taray.lambda-url.af-south-1.on.aws/",
      {
        keyword: keyword,
        endsWithKeyword,
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching users:", error);
    throw error;
  }
};

export const getUserByPrincipalFromAd = async (userPrincipalName) => {
  const data = JSON.stringify({
    userPrincipalName: userPrincipalName,
  });

  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://4d7juqwjhdw6kmi7gcpn2ofuem0zcgyi.lambda-url.af-south-1.on.aws/",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const response = await axios.request(config);
    console.log(JSON.stringify(response.data));
    return response.data; // Return the response data for further use
  } catch (error) {
    console.error("Error fetching user by principal:", error);
    throw error; // Throw the error for further error handling
  }
};

export const getUsersByDepartmentId = async (departmentId) => {
  try {
    const usersCollectionRef = collection(db, "users");

    const q = query(
      usersCollectionRef,
      where("department", "==", departmentId)
    );

    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const users = querySnapshot.docs.map((doc) => {
        const userData = doc.data();
        return { id: doc.id, ...userData };
      });

      return users;
    } else {
      throw new Error("No users exist in this department");
    }
  } catch (error) {
    console.error("Error fetching users:", error);
    // No need to wrap the error in a new Error object, just rethrow it
    throw error;
  }
};

const userCache = {};

export const getUserDetailsByUserPrincipalName = async (userPrincipalName) => {
  // Check the cache first
  if (userCache[userPrincipalName]) {
    return userCache[userPrincipalName];
  }

  try {
    const userDocRef = doc(db, "users", userPrincipalName);
    const docSnapshot = await getDoc(userDocRef);

    let userDetails;
    if (docSnapshot.exists()) {
      userDetails = {
        data: docSnapshot.data(),
        id: docSnapshot.id,
      };
      // Store the result in the cache
      userCache[userPrincipalName] = userDetails;
    } else {
      userDetails = {
        status: "notFound",
        data: null,
        id: null,
      };
    }
    return userDetails;
  } catch (error) {
    console.error("Error retrieving user details:", error);
    return {
      status: "error",
      data: null,
      id: null,
      message: error.message,
    };
  }
};

export const getUserByUserPrincipalName = async (userPrincipalName) => {
  try {
    const usersCollectionRef = collection(db, "users");

    const q = query(
      usersCollectionRef,
      where("mail", "==", userPrincipalName),
      limit(1)
    );

    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const userData = querySnapshot.docs[0].data();
      return userData;
    } else {
      let userDateFromAd = await getUserByPrincipalFromAd(userPrincipalName);
      if (!userDateFromAd.data.department) {
        if (
          userDateFromAd.data.userPrincipalName &&
          userDateFromAd.data.userPrincipalName.includes("fset")
        ) {
          userDateFromAd.data.department = "xYS5mWs6fBNiICfWXHsi"; // ICT
        } else {
          userDateFromAd.data.department = "sV9YR5nm1ZXNkfiFJKWP"; // GENERAL
        }
      }

      userDateFromAd = {
        ...userDateFromAd.data,
        isAdmin: false,
        isSpecialist: false,
        isSuperAdmin: false,
        profilePicture: "/",
        streetAddress: "",
        auditTrail: [],
        dateCreated: new Date(),
        dateUpdated: new Date(),
        userPrincipalName,
      };

      await saveUser(userDateFromAd);

      return userDateFromAd;
      // throw new Error(
      //   "User not existing. Newly added users take couple of hours to be avaliable on Service Desk."
      // );
    }
  } catch (error) {
    console.error("Error updating array:", error);
    throw new Error(error);
  }
};
export const saveUser = async (userData) => {
  try {
    const usersCollectionRef = collection(db, "users");
    const userDocRef = doc(usersCollectionRef, userData.userPrincipalName);

    await setDoc(userDocRef, userData, { merge: true });

    console.log(
      `User with principal name ${userData.userPrincipalName} saved/updated successfully.`
    );
  } catch (error) {
    console.error(`Error saving/updating user: ${error}`);
    throw new Error("Error occurred while trying to save/update user.");
  }
};

export const getAllUsers = async (dispatch, userDepartment, isSuperAdmin) => {
  const usersCollectionRef = collection(db, "users");
  let q = null;

  if (isSuperAdmin) {
    q = query(usersCollectionRef);
  } else {
    q = query(usersCollectionRef, where("department", "==", userDepartment));
  }

  const unsubscribe = onSnapshot(q, (snapshot) => {
    const users = snapshot.docs.map((doc) => {
      const userData = doc.data();
      return { id: doc.id, ...userData };
    });

    dispatch(setUsers(users));
  });

  return unsubscribe;
};

export const getAllUsersByDepartmentId = async (userDepartment) => {
  try {
    const usersCollectionRef = collection(db, "users");
    const q = query(
      usersCollectionRef,
      where("department", "==", userDepartment)
    );

    // Fetch the users once
    const querySnapshot = await getDocs(q);

    // Map over the snapshot to get the user data
    const users = querySnapshot.docs.map((doc) => {
      const userData = doc.data();
      return { id: doc.id, ...userData };
    });

    return users;
  } catch (error) {
    console.error("Error fetching users by department: ", error);
    return [];
  }
};

export const updateUserByUserPrincipalName = async (
  userPrincipalName,
  userData
) => {
  try {
    const usersCollectionRef = collection(db, "users");
    const userDocRef = doc(usersCollectionRef, userPrincipalName);

    const updateData = { ...userData };

    if (userData.hasOwnProperty("auditTrail")) {
      updateData.auditTrail = arrayUnion(userData.auditTrail);
    }

    await updateDoc(userDocRef, updateData);

    console.log(
      `User with principal name ${userPrincipalName} updated successfully.`
    );
  } catch (error) {
    console.error(`Error updating user: ${error}`);
    throw new Error("Error occurred while trying to update user.");
  }
};
